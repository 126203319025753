var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-0 pa-0"},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/view_subjects_modules","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Visualización de asignaturas y módulos ")])])]}}])}),_c('router-link',{attrs:{"to":"/evaluation_summary","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Resumen de notas ")])])]}}])}),_c('router-link',{attrs:{"to":"/profile/score_sheet","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(false)?_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Boleta de calificaciones ")])]):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }