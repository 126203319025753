import GeStudentApiService from "@/core/services/geStudentApi.service";
import { PedagogicalDiaryApiService } from "@/core/services/pedagogicalDiaryApi.service";

export const getLatestGradedSubevaluationsByUser = (
    user_uuid
) => {
    return GeStudentApiService.get(
        "dashboard/latest-scores/", user_uuid
    );
};

export const getLatestGradedSubevaluations = () => {
    return GeStudentApiService.get(
        "dashboard/latest-scores"
    );
}

export const getStudentDisciplineDetailsByTimePeriod = (student_UUID, start_date, end_date) => {
    return PedagogicalDiaryApiService.get(`student-class/report/studentId/${student_UUID}/startDate/${start_date}/endDate/${end_date}`
    );
};

export const getStudentStagesByGrade = (grade_id) => {
    return PedagogicalDiaryApiService.get(`dashboard/year-progress/grade/${grade_id}/speciality/8`
    );
};

export default {
    getStudentStagesByGrade,
    getLatestGradedSubevaluations,
    getLatestGradedSubevaluationsByUser,
    getStudentDisciplineDetailsByTimePeriod,
}
