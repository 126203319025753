<template>
  <div class="ma-0 pa-0">
    <!-- Normal scenario -->
    <ul class="menu-nav">
      <router-link
        to="/view_subjects_modules"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a
            :href="href"
            class="btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
            @click="navigate"
          >
            Visualización de asignaturas y módulos
          </a>
        </li>
      </router-link>
      <router-link
        to="/evaluation_summary"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a
            :href="href"
            class="btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
            @click="navigate"
          >
            Resumen de notas
          </a>
        </li>
      </router-link>
      <router-link
        to="/profile/score_sheet"
        v-slot="{ href, navigate, isActive, isExactActive }"
        custom
      >
        <li
        v-if="false"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
          ]"
        >
          <a
            :href="href"
            class="btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
            @click="navigate"
          >
            Boleta de calificaciones
          </a>
        </li>
      </router-link>

      <!-- <router-link
      to="/builder"
      v-slot="{ href, navigate, isActive, isExactActive }"
      custom
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Builder </span>
        </a>
      </li>
    </router-link> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
