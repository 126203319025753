var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"kt_header",staticClass:"header flex-column",attrs:{"id":"kt_header"}},[_c('div',{staticClass:"header-top test-color"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-none d-lg-flex align-items-center mr-3"},[_c('div',{staticClass:"mr-20"},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('img',{staticClass:"max-h-35px",attrs:{"alt":"Logo","src":_vm.layoutConfig('self.logo.default')}})])],1),_c('ul',{staticClass:"header-tabs nav align-self-end font-size-lg mb-n4",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"nav-link py-4 px-6 active",attrs:{"data-tab":"0","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":(e) => {
                    _vm.setActiveTab(e);
                    navigate(e);
                  }}},[_vm._v(" Inicio ")])]}}])})],1),_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":"/view_subjects_modules","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"nav-link py-4 px-6",attrs:{"data-tab":"1","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":(e) => {
                    _vm.setActiveTab(e);
                    navigate(e);
                  }}},[_vm._v(" Notas ")])]}}])})],1),_c('li',{staticClass:"nav-item mr-3"},[_c('router-link',{attrs:{"to":"/profile/student_profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"nav-link py-4 px-6",attrs:{"data-tab":"2","data-toggle":"tab","href":href,"role":"tab","aria-selected":"true"},on:{"click":(e) => {
                    _vm.setActiveTab(e);
                    navigate(e);
                  }}},[_vm._v("Mi perfil")])]}}])})],1)])]),_c('KTTopbar')],1)]),_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"container"},[_c('div',{ref:"kt_header_navs",staticClass:"header-navs header-navs-left",attrs:{"id":"kt_header_navs"}},[_c('ul',{staticClass:"header-tabs p-5 p-lg-0 d-flex flex-column d-lg-none nav nav-bold nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item mr-2"},[_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"nav-link btn btn-clean active",attrs:{"href":href,"data-tab":"0","data-toggle":"tab","data-target":"#kt_header_tab_1","role":"tab"},on:{"click":(e) => {
                    _vm.setActiveTab(e);
                    navigate(e);
                  }}},[_vm._v("Inicio")])]}}])})],1),_c('li',{staticClass:"nav-item mr-2"},[_c('router-link',{attrs:{"to":"/view_subjects_modules","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"nav-link btn btn-clean",attrs:{"href":href,"data-tab":"1","data-toggle":"tab","data-target":"#kt_header_tab_1","role":"tab"},on:{"click":(e) => {
                    _vm.setActiveTab(e);
                    navigate(e);
                  }}},[_vm._v("Notas")])]}}])})],1),_c('li',{staticClass:"nav-item mr-2"},[_c('router-link',{attrs:{"to":"/profile/student_profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"nav-link btn btn-clean",attrs:{"href":href,"data-tab":"2","data-toggle":"tab","data-target":"#kt_header_tab_2","role":"tab"},on:{"click":(e) => {
                    _vm.setActiveTab(e);
                    navigate(e);
                  }}},[_vm._v("Información personal")])]}}])})],1)]),_c('b-tabs',{staticClass:"hide-tabs",model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('b-tab',[_c('div',{staticClass:"tab-pane show active pt-2"},[_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/contact_numbers","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ],staticStyle:{"list-style":"none"},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-success font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Directorio telefónico ")])])]}}])}),_c('li',{staticClass:"menu-item",staticStyle:{"list-style":"none"},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-warning font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":"https://www.microsoft365.com/","target":"_blank"}},[_vm._v("Microsoft 365")])]),_c('li',{staticClass:"menu-item",staticStyle:{"list-style":"none"},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('v-btn',{staticClass:"redish font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2 text-h5 text-capitalize",attrs:{"loading":_vm.downloadingButton,"disabled":_vm.downloadingButton,"elevation":"0"},on:{"click":function($event){return _vm.getYearlyProgress()}}},[_vm._v("Descargar reporte de conducta PDF")])],1)],1)])])]),_c('b-tab',[_c('div',{staticClass:"tab-pane show active pt-2"},[_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[_c('KTMenu')],1)])]),_c('b-tab',[_c('div',{staticClass:"tab-pane show active pt-2"},[_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/profile/student_profile","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Mi información ")])])]}}])}),_c('router-link',{attrs:{"to":"/profile/downloads","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [(false)?_c('li',{staticClass:"menu-item",class:[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active',
                      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Descargas ")])]):_vm._e()]}}])})],1)])])]),_c('b-tab',{staticClass:"tab-pane pt-2 justify-content-between"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row align-items-start align-items-lg-center"},[_c('router-link',{attrs:{"to":"/contact_numbers","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ],staticStyle:{"list-style":"none"},attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"btn btn-light-success font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":href},on:{"click":navigate}},[_vm._v(" Directorio telefónico ")])])]}}])}),_c('a',{staticClass:"btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":_vm.isFromTerecerCiclo
                    ? 'http://avtercerciclo.ricaldone.edu.sv/'
                    : 'http://avbachillerato.ricaldone.edu.sv/',"target":"_blank"}},[_vm._v(_vm._s(_vm.isFromTerecerCiclo ? "AV Tercer ciclo" : "AV Bachillerato"))]),_c('a',{staticClass:"btn btn-light-warning font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2",attrs:{"href":"https://sge.ricaldone.edu.sv/inicio","target":"_blank"}},[_vm._v("SGE")])],1),_c('div',{staticClass:"d-flex align-items-center"})])],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }