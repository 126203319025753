<template>
  <div id="kt_header" ref="kt_header" class="header flex-column">
    <div class="header-top test-color">
      <div class="container">
        <div class="d-none d-lg-flex align-items-center mr-3">
          <!--begin::Logo-->
          <div class="mr-20">
            <router-link to="/dashboard">
              <img
                alt="Logo"
                :src="layoutConfig('self.logo.default')"
                class="max-h-35px"
              />
            </router-link>
          </div>
          <!--end::Logo-->

          <ul
            class="header-tabs nav align-self-end font-size-lg mb-n4"
            role="tablist"
          >
            <li class="nav-item">
              <router-link to="/dashboard" v-slot="{ href, navigate }" custom>
                <a
                  class="nav-link py-4 px-6 active"
                  @click="
                    (e) => {
                      setActiveTab(e);
                      navigate(e);
                    }
                  "
                  data-tab="0"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                >
                  Inicio
                </a>
              </router-link>
            </li>
            <li class="nav-item mr-3">
              <router-link
                to="/view_subjects_modules"
                v-slot="{ href, navigate }"
                custom
              >
                <a
                  class="nav-link py-4 px-6"
                  @click="
                    (e) => {
                      setActiveTab(e);
                      navigate(e);
                    }
                  "
                  data-tab="1"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                >
                  Notas
                </a>
              </router-link>
            </li>
            <li class="nav-item mr-3">
              <router-link
                to="/profile/student_profile"
                v-slot="{ href, navigate }"
                custom
              >
                <a
                  class="nav-link py-4 px-6"
                  @click="
                    (e) => {
                      setActiveTab(e);
                      navigate(e);
                    }
                  "
                  data-tab="2"
                  data-toggle="tab"
                  :href="href"
                  role="tab"
                  aria-selected="true"
                  >Mi perfil</a
                >
              </router-link>
            </li>
            <!-- <li class="nav-item mr-3">
              <a
                class="nav-link py-4 px-6"
                @click="setActiveTab"
                data-tab="3"
                data-toggle="tab"
                href="#"
                role="tab"
                aria-selected="true"
                >Otros</a
              >
            </li> -->
          </ul>
        </div>
        <KTTopbar></KTTopbar>
      </div>
    </div>
    <div class="header-bottom">
      <div class="container">
        <div
          class="header-navs header-navs-left"
          id="kt_header_navs"
          ref="kt_header_navs"
        >
          <!-- normal scenario -->
          <ul
            class="header-tabs p-5 p-lg-0 d-flex flex-column d-lg-none nav nav-bold nav-tabs"
            role="tablist"
          >
            <li class="nav-item mr-2">
              <router-link to="/dashboard" v-slot="{ href, navigate }" custom>
                <a
                  :href="href"
                  class="nav-link btn btn-clean active"
                  @click="
                    (e) => {
                      setActiveTab(e);
                      navigate(e);
                    }
                  "
                  data-tab="0"
                  data-toggle="tab"
                  data-target="#kt_header_tab_1"
                  role="tab"
                  >Inicio</a
                >
              </router-link>
            </li>
            <li class="nav-item mr-2">
              <router-link
                to="/view_subjects_modules"
                v-slot="{ href, navigate }"
                custom
              >
                <a
                  :href="href"
                  class="nav-link btn btn-clean"
                  @click="
                    (e) => {
                      setActiveTab(e);
                      navigate(e);
                    }
                  "
                  data-tab="1"
                  data-toggle="tab"
                  data-target="#kt_header_tab_1"
                  role="tab"
                  >Notas</a
                >
              </router-link>
            </li>
            <li class="nav-item mr-2">
              <router-link
                to="/profile/student_profile"
                v-slot="{ href, navigate }"
                custom
              >
                <a
                  :href="href"
                  class="nav-link btn btn-clean"
                  @click="
                    (e) => {
                      setActiveTab(e);
                      navigate(e);
                    }
                  "
                  data-tab="2"
                  data-toggle="tab"
                  data-target="#kt_header_tab_2"
                  role="tab"
                  >Información personal</a
                >
              </router-link>
            </li>
            <!-- <li class="nav-item mr-2">
              <a
                href="#"
                class="nav-link btn btn-clean"
                @click="setActiveTab"
                data-tab="3"
                data-toggle="tab"
                data-target="#kt_header_tab_2"
                role="tab"
                >Otros</a
              >
            </li> -->
          </ul>

          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab>
              <div class="tab-pane show active pt-2">
                <!--begin::Menu-->
                <div
                  id="kt_header_menu"
                  ref="kt_header_menu"
                  class="header-menu header-menu-mobile header-menu-layout-default"
                >
                  <ul class="menu-nav">
                    <!--begin::Actions-->
                    <router-link
                      to="/contact_numbers"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      custom
                    >
                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        style="list-style: none"
                        :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ]"
                      >
                        <a
                          :href="href"
                          class="btn btn-light-success font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                          @click="navigate"
                        >
                          Directorio telefónico
                        </a>
                      </li>
                    </router-link>

                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      style="list-style: none"
                    >
                      <a
                        href="https://www.microsoft365.com/"
                        target="_blank"
                        class="btn btn-light-warning font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                        >Microsoft 365</a
                      >
                    </li>
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      style="list-style: none"
                    >
                      <v-btn
                        :loading="downloadingButton"
                        :disabled="downloadingButton"
                        @click="getYearlyProgress()"
                        elevation="0"
                        class="redish font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2 text-h5 text-capitalize"
                        >Descargar reporte de conducta PDF</v-btn
                      >
                    </li>
                  </ul>
                </div>
                <!--end::Menu-->
              </div>
            </b-tab>
            <b-tab>
              <div class="tab-pane show active pt-2">
                <!--begin::Menu-->
                <div
                  id="kt_header_menu"
                  ref="kt_header_menu"
                  class="header-menu header-menu-mobile header-menu-layout-default"
                >
                  <KTMenu></KTMenu>
                </div>
                <!--end::Menu-->
              </div>
            </b-tab>

            <b-tab>
              <div class="tab-pane show active pt-2">
                <!--begin::Menu-->
                <div
                  id="kt_header_menu"
                  ref="kt_header_menu"
                  class="header-menu header-menu-mobile header-menu-layout-default"
                >
                  <ul class="menu-nav">
                    <router-link
                      to="/profile/student_profile"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      custom
                    >
                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ]"
                      >
                        <a
                          :href="href"
                          class="btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                          @click="navigate"
                        >
                          Mi información
                        </a>
                      </li>
                    </router-link>
                    <router-link
                      to="/profile/downloads"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      custom
                    >
                      <li
                      v-if="false"
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ]"
                      >
                        <a
                          :href="href"
                          class="btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                          @click="navigate"
                        >
                          Descargas
                        </a>
                      </li>
                    </router-link>
                  </ul>
                </div>
                <!--end::Menu-->
              </div>
            </b-tab>

            <b-tab class="tab-pane pt-2 justify-content-between">
              <div
                class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
              >
                <!--begin::Actions-->
                <router-link
                  to="/contact_numbers"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  custom
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    style="list-style: none"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active',
                    ]"
                  >
                    <a
                      :href="href"
                      class="btn btn-light-success font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                      @click="navigate"
                    >
                      Directorio telefónico
                    </a>
                  </li>
                </router-link>
                <a
                  :href="
                    isFromTerecerCiclo
                      ? 'http://avtercerciclo.ricaldone.edu.sv/'
                      : 'http://avbachillerato.ricaldone.edu.sv/'
                  "
                  target="_blank"
                  class="btn btn-light-primary font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                  >{{
                    isFromTerecerCiclo ? "AV Tercer ciclo" : "AV Bachillerato"
                  }}</a
                >
                <a
                  href="https://sge.ricaldone.edu.sv/inicio"
                  target="_blank"
                  class="btn btn-light-warning font-weight-bold my-2 my-lg-0 mx-5 mx-sm-2"
                  >SGE</a
                >

                <!--end::Actions-->
              </div>
              <div class="d-flex align-items-center">
                <!--begin::Actions-->
                <!-- <a href="#" class="btn btn-danger font-weight-bold my-2 my-lg-0"
                  >Generate Reports</a
                > -->
                <!--end::Actions-->
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenu from "@/view/layout/header/Menu.vue";
import dashboardRepository from "@/repositories/dashboardRepository";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");
import axios from "axios";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTHeader",
  data() {
    return {
      downloadingButton: false,
      tabIndex: 0,
      yearlyProgress: {},
      isLoadingCurrentStage: false,
    };
  },
  // watch: {
  //   "currentUserPersonalInfo.grade_id": function (newValue, oldValue) {
  //     if (!!newValue) {
  //       this.getYearlyProgress();
  //     }
  //   },
  // },
  components: {
    KTTopbar,
    KTMenu,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "currentUserPersonalInfo"]),

    baseDP_API_URL() {
      let baseURL;
      if (process.env.DP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.DP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },

    isFromTerecerCiclo() {
      if (!("speciality_id" in this.currentUserPersonalInfo)) {
        return false;
      }
      return this.currentUserPersonalInfo.speciality_id == 8;
    },
  },
  methods: {
    reportURL() {
      this.downloadingButton = true;
      let sortedStagesArrayLength = this.yearlyProgress.sortedStages?.length;
      axios({
        url: `${this.baseDP_API_URL}/student-class/document-report/student/${this.currentUserPersonalInfo.uuid}/start-date/${this.yearlyProgress.sortedStages[0]?.start}/end-date/${this.yearlyProgress.sortedStages[sortedStagesArrayLength -1]?.end}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `Reporte de conducta - ${
              this.currentUserPersonalInfo.full_name
            } - ${this.currentUserPersonalInfo?.code} - ${dayjs().format(
              "DD-MM-YYYY"
            )}.pdf`
          );
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((e) => {
          console.error(e);
          this.fireToast({
            title: "Ocurrió un error al descargar el reporte",
          });
        })
        .finally(() => {
          this.downloadingButton = false;
        });
    },
    getYearlyProgress() {
      this.isLoadingCurrentStage = true;
      dashboardRepository
        .getStudentStagesByGrade(this.currentUserPersonalInfo.grade_id)
        .then(({ data }) => {
          this.yearlyProgress = data;
          this.reportURL();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoadingCurrentStage = false;
        });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
};
</script>

<style>
.test-color {
  background-color: #263238 !important;
}

.test-color-second {
  background-color: #263238 !important;
}

.redish {
  background: #bbdefb !important;
  color: #2196f3 !important ;
  transition: 0.35s !important;
  font-family: Poppins, Helvetica, "sans-serif";
}

.redish:hover {
  background: #2196f3 !important;
  color: #ffffff !important ;
}

@media (max-width: 990px) {
  .test-color-second {
    background-color: #ffb300 !important;
    border-radius: 0px 0px 5px 5px;
  }
}
</style>
